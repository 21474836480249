export const InitOldAccordions = () => {
    const accordion = {
        init: function (target) {
            let headers = document.querySelectorAll('.faq-item');
            if (headers.length > 0) {
                for (let head of headers) {
                    head.addEventListener('click', accordion.select);
                }
            }
        },

        select: function () {
            this.classList.toggle('collapsed');
        },
    };

    accordion.init('.faq-wrapper');
};
