import { scrollToElem } from '../helper-function/scrollToElem';

export const initMobileMenu = () => {
    // VARS
    const hamburger = document.querySelector('[data-hamburger]');
    const header = document.querySelector('[data-header]');
    const anchorLinks = document.querySelectorAll('[data-anchor]');
    const menuList = document.querySelector('[data-menu-list]');
    if (!hamburger) return;

    // LISTENERS
    hamburger.addEventListener('click', handleOnClickHamburger, false);

    [...anchorLinks].forEach((link) => {
        link.addEventListener('click', handleOnClickAnchorLink, false);
    });

    window.addEventListener('resize', function () {
        if (window.innerWidth >= 781 && hamburger.classList.contains('active')) {
            hamburger.click();
        }
    });

    // HANDLERS
    function handleOnClickHamburger() {
        toggleMenu();
    }

    function handleOnClickAnchorLink(event) {
        const pageSlug = menuList.dataset.menuList;
        const selectorAnchor = this.dataset.anchor;

        if (pageSlug === 'umobixco_home_page') {
            event.preventDefault();
        }

        enableScroll();
        closeMenu();
        scrollToElem(selectorAnchor, -50);
    }

    // FUNCTIONS
    function toggleMenu() {
        if (hamburger.classList.contains('active')) {
            closeMenu();
            enableScroll();
        } else {
            openMenu();
            disableScroll();
        }
    }

    function openMenu() {
        hamburger.classList.add('active');
        header.classList.add('open-menu');
    }

    function closeMenu() {
        hamburger.classList.remove('active');
        header.classList.remove('open-menu');
    }
};
