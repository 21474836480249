import AOS from '../tools/aos';

export const initAnimationOnScroll = () => {
    AOS.init({
        offset: 200,
        duration: 600,
        easing: 'ease-in-sine',
        delay: 100,
        disable: 'mobile',
    });
};
